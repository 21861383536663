/*
* ===================================================
*     Products 
* ===================================================
*/

.product-grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    color: $gray-600;
    strong {
        color: $gray-700;
    }
}

.product-grid-header-show {
    display: inline-block;
    padding: .3rem;
    color: $gray-700;
    margin-right: .5rem;
    &.active {
        border-bottom: solid 2px $gray-700;
    }
    @include hover-focus() {
        text-decoration: none;
        border-bottom: solid 2px $gray-700;
    }
}

.product {
    margin-bottom: $grid-gutter-width;
}

.product-image {
    position: relative;
    border-bottom: 2px solid $gray-700;
    &:hover {
        .product-hover-overlay {
            opacity: 1;
        }
        .btn {
            transform: none !important;
            opacity: 1 !important;
        }
        border-bottom: 2px solid theme-color('primary');
    }
}

.product-hover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($white, 0.92);
    opacity: 0;
    transition: opacity 0.3s;
    .product-hover-overlay-buttons {
        z-index: 3;
    }
    .btn {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        padding: 0;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, opacity .15s ease-in-out;
        &.btn-product-left {
            transform: translateX(-20px);
            opacity: 0;
        }
        &.btn-product-right {
            transform: translateX(20px);
            opacity: 0;
        }
        &.btn-buy {
            width: auto;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-left: .5rem;
            margin-right: .5rem;
        }
    }
    .product-hover-overlay-link {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

.product-columns {
    
    @include media-breakpoint-up(sm) {
      column-count: 2 !important;
    }

    @include media-breakpoint-up(md) {
      column-count: 3 !important;
    }
  }