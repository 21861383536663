/*
* ===================================================
*     Block 
* ===================================================
*/

.block-header {
    padding: 1.2rem 1.5rem;
    background: $gray-100;
}

.block-body {
    padding: 1.2rem 1.5rem;
}