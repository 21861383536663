/*
* ===================================================
*     Breadcrumb
* ===================================================
*/

.breadcrumb {
    font-size: $font-size-sm;
    a {
        color: $gray-900;
    }
}