/*
* ===================================================
*     Quickview Modal
* ===================================================
*/

.quickview {
    background: rgba($white, .2); 
    .ribbon {
        position: absolute;
        top: 30px;
        left: -12px;
        z-index: 999;
        &+.ribbon {
            top: 80px;
            &+.ribbon {
                top: 145px;
            }
        }        
    }
}

/* Quickview Modal Media Query -------------------------- */

@include media-breakpoint-down(md) {
    .quickview {
        text-align: center;
        .detail-quantity {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.close.modal-close {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: theme-color('dark');
    color: $white !important;
    position: absolute;
    top: -5px;
    right: -5px;
    opacity: 1;
    font-size: .8rem;
    z-index: 999;
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: 1 !important;
    }
}

