/*
* ===================================================
*     Product detail
* ===================================================
*/
.detail-images {
    display: flex;
    margin-bottom: 3rem;
}

.detail-thumb-item {
    display: block;
    max-width: 100%;
    background: none;
    border: none;
    opacity: 0.5;
    cursor: pointer;
    outline: none !important;
    padding: 0;

    &.active {
        opacity: 1;
    }
}

.detail-carousel {
    position: relative;

    .ribbon {
        position: absolute;
        top: 20px;
        left: 4px;
        z-index: 999;

        &+.ribbon {
            top: 80px;

            &+.ribbon {
                top: 145px;
            }
        }
    }
}

.detail-option-heading {
    @include uppercase;
    font-size: .9rem;
    color: $gray-700;

    span {
        font-weight: normal;
        font-size: .8rem;
        text-transform: none;
        font-family: $font-family-base;
        color: $gray-500;
    }
}

.detail-option-btn-label {
    position: relative;

    &.active {
        box-shadow: none !important;
    }
}

.detail-quantity {
    max-width: 5rem;
    text-align: center;
}

.detail-full-item {
    width: 100%;
    min-height: 600px;

    @include media-breakpoint-up(lg) {
        height: calc(100vh - #{$headerHeight} - 2rem);
    }
}

.detail-full-item-modal {
    width: 100%;
    min-height: 600px;
    height: calc(100vh - #{$headerHeight} - 5rem);
}

.detail-nav-link.nav-link {
    color: $gray-600;
    text-transform: uppercase;
    letter-spacing: .1em;
    border-color: #fff #fff #ddd;
    font-size: .8rem;
    padding: 1rem 1.5rem;
    font-weight: 700;

    &.active,
    &:hover,
    &:focus {
        color: theme-color('dark');
        border-color: #fff #fff theme-color('dark') !important;
    }
}

.review {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid $gray-200;

    &:first-of-type {
        padding-top: 1rem;
    }

    &:last-of-type {
        margin-bottom: 0;
        border-bottom: none;
    }
}

.review-image {
    display: block;
    width: 120px;
    max-width: 100%;
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0 auto .5rem;
    background: #fff;
    border: solid 1px $list-group-border-color;

    @include media-breakpoint-down(sm) {
        width: 100px;
        padding: .4rem;
    }
}

.review-text {
    padding-top: 1rem;
}