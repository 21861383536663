// this is the place for your components / CSS

// Scale h1 size for smaller devices
@include media-breakpoint-down(lg) {
    h1.product {
        font-size: $h1-font-size * .85;
    }
    h2.product {
        font-size: $h2-font-size * .85;
    }
    h3.product {
        font-size: $h3-font-size * .85;
    }
}
@include media-breakpoint-down(md) {
    h1.product {
        font-size: $h1-font-size * .7;
    }
    h2.product {
        font-size: $h2-font-size * .7;
    }
    h3.product {
        font-size: $h3-font-size * .7;
    }
}
@include media-breakpoint-down(sm) {
    h1.product {
        font-size: $h1-font-size * .5;
    }
    h2.product {
        font-size: $h2-font-size * .5;
    }
    h3.product {
        font-size: $h3-font-size * .5;
    }
}
// Scale h1 and h2 in home directory and top carousel
@include media-breakpoint-up(lg) {
    h1.homedir {
        font-size: $h1-font-size * .75;
    }
    h2.homedir {
        font-size: $h2-font-size * .75;
    }
}
@include media-breakpoint-down(sm) {
    h1.homedir, h1.homecarousel {
        font-size: $h1-font-size * .7;
    }
    h2.homedir {
        font-size: $h2-font-size * .75;
    }
}

.text-base {
  font-size: $font-size-base;
}

// Used in the very bottom footer
.text-decoration-underline {text-decoration: underline !important}

// Used for discounted prices
.text-decoration-line-through {text-decoration: line-through !important}

// Prevent in-page anchors from displaying behinf the stocky navbar
.link-target {scroll-margin-top:90px;}

// Elegir un jamón
details > details {padding-left: 2em}
details > summary {color: $primary}

// Achieve correct vertical alignment between flag icon and side text
.topbar-flag {padding-bottom: 3px}

.columns-2 {column-count: 2} // Used in footer
.columns-3 {column-count: 3} // Use in shipping page

// responsive 2-column layout
@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .columns#{$infix}-2 {
        @include media-breakpoint-up($next) {
            column-count: 2;
        }
    }
    // prevent breaks within inner elements
    .columns#{$infix}-2 * {
        @include media-breakpoint-up($next) {
				-webkit-column-break-inside: avoid;
									page-break-inside: avoid;
											break-inside: avoid;
				}
		}
		
    .columns#{$infix}-3 {
        @include media-breakpoint-up($next) {
            column-count: 3;
        }
    }
    // prevent breaks within inner elements
    .columns#{$infix}-3 * {
        @include media-breakpoint-up($next) {
				-webkit-column-break-inside: avoid;
									page-break-inside: avoid;
											break-inside: avoid;
				}
		}
}

// Make container wider (was 270px)
.navbar-cart-product-wrapper {min-width: 370px}

// Larger quantity increase/decrease buttons in cart, color change on hover
.btn-items {font-size: 1.4rem; letter-spacing: 0}
.btn.btn-items {padding-left:0.3rem; padding-right:0.3rem}
.btn.btn-items:hover, .btn.btn-items:focus {color: #60686f !important}

// Smaller quantity inputs (original was min-width:40px)
.input-items {width: 30px}
.input-items[readonly] {background-color: transparent}

// Left margin and alignment for order summary prices (cart & checkout)
.order-summary-item > *:nth-child(2) {margin-left:1rem; text-align:right}

// Checkout steps width
.w-20 {width: 20% !important;}

// Underline links in labels of invalid inputs to avoid unintended navigation
.was-validated .form-check-input:invalid ~ .form-check-label a, .form-check-input.is-invalid ~ .form-check-label a {text-decoration: underline !important}
.was-validated .custom-control-input:invalid ~ .custom-control-label a, .custom-control-input.is-invalid ~ .custom-control-label a {text-decoration: underline !important}

.flex-gap-0 {gap: 0.3rem;}
.flex-gap-1 {gap: 1rem;}
// Workaround for Safari not supporting flex gap https://stackoverflow.com/a/67133945/1091231
/*.flex-gap-0 > *:not(:last-child) { margin-right: 0.3rem; }
.flex-gap-0 > * { margin-bottom: 0.3rem; }
.flex-gap-1 > *:not(:last-child) { margin-right: 1rem; }
.flex-gap-1 > * { margin-bottom: 1rem; }*/

// Override theme for tables (width: 100%)
.table {width: auto}

// Used in product option buttons
.btn-product-option {letter-spacing: .2em}

// Used in product quantity, right by the add-to-cart button
.line-height-normal {line-height: normal}

// Shipping info tables
.table-shipping thead th { text-align: center }
.table-shipping [scope][rowspan] + td + td { text-align: center }
.table-shipping *:not([rowspan]) + td { text-align: center }

// Used in wholesale sale index page
.list-checkmarks li {
  list-style-type:none;
  padding-left:1rem;
}
.list-checkmarks li:before {    
	font-family: 'FontAwesome';
	content: "\f00c";
	margin:0 10px 0 -28px;
	color: #17aa1c;
}

// Ribbon for bio products
.ribbon-eco {
  background-color: Green !important;
}
.ribbon-eco::before {
  border-top-color: DarkGreen;
}

// Ribbon for pata negra products
.ribbon-pata-negra {
  background-color: #333 !important;
}
.ribbon-pata-negra::before {
  border-top-color:  #000;
}

// Reduce space between ribbons
.ribbon + .ribbon {
	top: 50px;
}
.ribbon + .ribbon + .ribbon {
	top: 90px;
}
.ribbon + .ribbon + .ribbon + .ribbon {
	top: 130px;
}

.detail-carousel .ribbon + .ribbon {
	top: 60px;
}
.detail-carousel .ribbon + .ribbon + .ribbon {
	top: 100px;
}
.detail-carousel .ribbon + .ribbon + .ribbon + .ribbon {
	top: 150px;
}

// Reduce ribbon text spacing
.ribbon {
	letter-spacing: .15em;
}

// Add slight border to images in product listings bc most of our backgrounds are white and ribbons seem to be hanging in the air
/*.product-image {
	border-left: 1px solid $gray-200;
	border-right: 1px solid $gray-200;
	border-top: 1px solid $gray-200;
}*/

// Add slight border to images in main product page
/*.main-product-image {
	border: 1px solid $gray-200;
}
.detail-thumb-item img {
	border: 1px solid $gray-600;
}*/

// Star ratings (based on https://gist.github.com/keiffer-filweb/df7c1b1a64eb6abe2a60cf0efe249efa )
.rating {
  display: inline-block;
  unicode-bidi: bidi-override;
/*   color: $gray-500; */
  width: auto;
  margin: 0;
/*   position: relative; */
  padding: 0;
}
/*.rating-upper {
  color: $primary;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
  overflow: hidden;
}
.rating-lower {
  padding: 0;
  display: flex;
  z-index: 0;
}
.star-on, .star-off {
	display: inline-block;
}*/
.star-on {
	color: $primary;	
}
.star-off {
	color: $gray-500;	
}

// Tns carousel navigation styles
/*.detail-thumb-item.tns-nav-active { opacity: 1 }
.tns-nav {
  text-align: center;
  margin: 10px 0;
}
.tns-nav > [aria-controls]:hover {
	background: none;
	text-decoration: none
}
.tns-nav > [aria-controls] {
  padding: 0;
  background: none;
  border-style: none;
}
.tns-nav > [aria-controls] > span {
	display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px 2px 4px;
  border-radius: 30px;
  background: $primary;
	backface-visibility: visible;
	transition: opacity 200ms ease;
}
.tns-nav > .tns-nav-active > span {
  width: 12px;
  height: 12px;	
	margin-bottom: 0;
}
.tns-nav > button[aria-controls] {
  padding: 0;
  width: 8px;
  height: 8px;
	margin: 0 5px;
	border-radius: 50%;
	border: 0;
}*/

.tns-controls [disabled] { 
  color: #999999;
  background: #B3B3B3; 
  cursor: not-allowed !important;
}
.tns-nav {
  text-align: center;
  margin: 10px 0;
}
.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
/*   background: #f5afb5; // #ddd; */
  border: 0;
}
.tns-nav > .tns-nav-active {
	background: $primary;
  width: 12px;
  height: 12px;
}
.product-slider-item .product {
	margin-bottom: 1rem;
}

// Home carousel
.tns-carousel .tns-controls button.tns-prev,
.tns-carousel .tns-controls button.tns-next {
	background: none;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit;
}
.tns-controls button.tns-prev,
.tns-controls button.tns-next {
	background: none;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit;
}
.tns-theme .tns-controls {
 position: absolute;
 width: 100%;
 top: 50%;
 left: 0;
 height: 50px;
/* Si dejamos estas lineas de transform, el z-index de los controles del carrusel de la home no funcionan
 -webkit-transform: translateY(-50%);
 transform: translateY(-50%);*/
 text-align: center;
 -webkit-tap-highlight-color: transparent
}
.tns-theme .tns-controls [class*='tns-'] {
  position: absolute;
  top: 0;
  left: 40px;
  color: #FFF;
  font-size: 14px;
  margin: 0;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.tns-theme .tns-controls .tns-next {
  left: auto;
  right: 40px;
}
.tns-carousel .tns-item img {
	display: block;
	width: 100%; }
.home-full-slider .home-full-item {
	padding-top: 0;
	padding-bottom: 0;
}
.product-slider-wrapper .tns-theme .tns-controls [class*='tns-'] {
  color: #000;
}
.product-slider-wrapper .tns-theme .tns-controls .tns-prev {
  left: -20px;
}
.product-slider-wrapper .tns-theme .tns-controls .tns-next {
  right: -30px;
}

// Rankings
.badge.ranking-position {
	font-weight: bold;
	font-size: 0.8rem;
}

.modal .d-none-within-modal {
	display: none;
}

.garantia_title {
	font-size: 1.1rem;
	margin-bottom: 0.5rem
}

.detail-nav-link.nav-link {
	padding-left: 1rem;
	padding-right: 1rem;
}

// Disabled product options
.btn.disabled {
	border-style: dashed;
}

// Make captions wrap to figure widths (https://github.com/twbs/bootstrap/issues/29492#issue-502504545)
.figure{
	display: table;
}
.figure-caption{
	display: table-caption;
	caption-side: bottom;
}

/*// Make URLs with fragments position correctly, accounting for the vertical space taken by the fixed navbar (https://stackoverflow.com/a/31808644/1091231)
a[id]:before {
	display: block;
	content:" ";
	margin-top: -81px;
	height: 81px;
	visibility: hidden;
}*/

// Logo font
@font-face {
	font-family: bodoni_bk_bt;
	src: url(/css/bodoni_bk_bt.ttf);
	font-display: swap;
}

.logo {
	font-family: bodoni_bk_bt;
	font-size: 45px;
	line-height: 60%;
}
.logo-secondary {
	color: #9c102e;
}
.navbar-light .navbar-nav .show > .logo,
.navbar-light .navbar-nav .active > .logo {
  color: rgba(0, 0, 0, 0.95);
}
.navbar-dark .navbar-nav .show > .logo,
.navbar-dark .navbar-nav .active > .logo {
  color: #fff;
}

// Text shadow
.text-shadow {
	text-shadow: 1px 1px gray;
}

// Do not limit tooltip widths (some won't fit into the theme's default 200px)
.tooltip-inner {
	max-width: none;
}

// Limit certain tooltips
.tooltip-inner.tooltip-400 {
  max-width: 400px;
}

// Used in the home-page
.dark-overlay.opacity-1::after {
	opacity: .1;
}
.text-light .text-shadow {
	text-shadow: 1px 1px 2px #000;
}
.home-full-slider-wrapper.h-auto {
	min-height: 0;
}
.home-full-slider-wrapper .blockquote {
	border-left-style: none;
	font-size: 2.9vmax;
}
.home-full-slider-wrapper .blockquote::before {
	font-family: "Playfair Display", serif !important;
  position: absolute;
  content: "“";
  font-size: 8vmax;
  margin-left: -0.6em;
  margin-top: -0.4em;
}

.prepend-asterisc > *:first-child::before {
	content: "* ";
}

.listing-filter {
/*	border-color: #ced4da;*/
	color: rgb(73, 80, 87);
}
.listing-filter.dropdown-toggle::after {
	border-top: 0.4em solid;
	border-right: 0.4em solid transparent;
	border-left: 0.4em solid transparent;	
}

// CSS arrows https://www.w3schools.com/howto/howto_css_arrows.asp
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}
.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

// jquery.pan controls
.panWrapper a.controls {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 2rem;
	color: white;
	text-decoration: none;
	background-image: none !important;
}
.panWrapper a.controls.close::before {
	content: "\f057";
}
.panWrapper .buttonsWrapper a.controls.in::before {
	content: "\f00e";
}
.panWrapper .buttonsWrapper a.controls.out::before {
	content: "\f010";
}
.panWrapper .buttonsWrapper a.controls.rc::before {
	content: "\f01e";
}
.panWrapper .buttonsWrapper a.controls.ra::before {
	content: "\f0e2";
}
.panWrapper .loading{
	display: none; // For whatever reason, this sometimes won't disappear once the image is loaded'
}

// Safari misc
.custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
}