/*
* ===================================================
*     Docs
* ===================================================
*/

.docs-item {
    padding: 2.5rem 0;
    border-bottom: 1px solid $gray-300;
    margin-bottom: .5rem;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        border-bottom: none;
    }
    .docs-desc {
        font-size: $font-size-lg;
        color: $gray-600;
        p.lead {
            color: $body-color;
        }
    }
}