/*
* ===================================================
*     Map
* ===================================================
*/


.map-custom-popup {

    .leaflet-popup-content-wrapper {
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        font-family: $font-family-base;
        font-size: 1rem;
        line-height: 1.5;
    }

    .leaflet-popup-content {
        margin: 0;
        background: #fff;
    }

    .leaflet-popup-tip {
        background: #fff;
        //box-shadow: none;
    }

    a.leaflet-popup-close-button {
        display: inline-block;
        width: 30px !important;
        height: 30px !important;
        padding: 0 !important;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        color: #fff !important;
        background: theme-color('dark') !important;
        font-weight: bold;
        line-height: 30px !important;
    }

    .leaflet-popup-content {
        p {
            margin: 0 0 1rem;
        }

        a {
            color: $link-color;
        }
    }
}
